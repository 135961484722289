<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'REGISTRATION'"></h4>

            <div>
                <btn text="Backup" icon="fa fa-download" :loading="downloading"
                     loading-text="Downloading.." @click="downloadBackup" />
            </div>
        </div>
        <vue-table :fields="fields" :url="url" :per-page="10" ref="table" />
    </div>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'Registration',
    data () {
        return {
            downloading : false,
            url         : urls.admin.report.register,
            fields      : [
                {
                    name       : 'choose_course',
                    sortField  : 'choose_course',
                    title      : 'Course',
                    titleClass : ''
                },
                {
                    name       : 'date',
                    sortField  : 'date',
                    title      : 'Date and location',
                    titleClass : ''
                },
                {
                    name       : 'country',
                    sortField  : 'country',
                    title      : 'Country',
                    titleClass : ''
                },
                {
                    name       : 'created_at',
                    sortField  : 'created_at',
                    title      : 'Reg. Date/Time',
                    titleClass : ''
                },
                {
                    name       : 'name',
                    sortField  : 'name',
                    title      : 'Name',
                    titleClass : ''
                },
                {
                    name       : 'phone_number',
                    sortField  : 'phone_number',
                    title      : 'Phone Number',
                    titleClass : ''
                },
                {
                    name       : 'email',
                    sortField  : 'email',
                    title      : 'email',
                    titleClass : ''
                }
            ]
        };
    },

    methods : {

        downloadBackup () {
            const that = this;

            that.downloading = true;

            axios.form(urls.backup.register, {}).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.downloading = false;
                    window.open(json.url);
                } else {
                    that.downloading = false;
                    that.$notify('', json.message, {
                        type : 'warning'
                    });
                }
            }).catch(function (exception) {
                that.downloading = false;
                console.log('exception occurred at backup : ', exception);
                that.$notify('Something went wrong..!!', 'Error', {
                    type : 'warning'
                });
            });
        }
    }
};
</script>

<style scoped>

</style>
